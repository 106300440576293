/** @jsx jsx */
import { useState, useEffect } from 'react'
import { jsx, Flex, Box } from 'theme-ui';
import { Radio } from '../form'
import { Stack } from 'raam'
import Wrapper from '../layout/wrapper'
import Inner from '../layout/inner'
import Kit from './kit'

import Pink from '../../images/kit/goal.png'
import Green from '../../images/kit/home.png'
import Grey from '../../images/kit/away.png'
import Blue from '../../images/kit/blue.png'

import PinkFront from '../../images/kit/goal-front.png'
import GreenFront from '../../images/kit/home-front.png'
import GreyFront from '../../images/kit/away-front.png'
import BlueFront from '../../images/kit/blue-front.png'

const KitPicker = (props) => {

  const options = [
    {
      label: 'Home',
      value: 'home',
      kit: Green,
      kitFront: GreenFront,
      color: 'primarydark'
    },
    {
      label: 'Away',
      value: 'away',
      kit: Grey,
      kitFront: GreyFront,
      color: 'away'
    },
    {
      label: 'Third',
      value: 'third',
      kit: Blue,
      kitFront: BlueFront,
      color: 'third'
    },
    {
      label: 'Goalkeeper',
      value: 'goalkeeper',
      kit: Pink,
      kitFront: PinkFront,
      color: 'goalkeeper'
    },
  ]

  const choicesSX = {
    display: 'flex',
    justifyContent: 'center',
  }

  const initialIndex = props.default ? options.findIndex(x => ( x.value === props.default )) : 0
  const [selected, setSelected] = useState(options[initialIndex >= 0 ? initialIndex : 0])

  useEffect(() => {
    if (props.onChange)
      props.onChange(selected)
  }, [
    selected,
    props.onChange,
  ])

  const handleChange = (e) => {
    const option = options.find((x) => {
      return x.value === e.target.value
    })
    setSelected(option)
  }

  return (
    <Box className={props.className}>
      <Flex sx={{justifyContent: 'center'}}>
        <Kit src={selected.kitFront} label={selected.label} front />
      </Flex>
      <Wrapper>
        <Inner sx={choicesSX}>
          <Stack>
            {options.map((x,i) => {
              return (
                <Radio 
                  key={i}
                  label={x.label} 
                  name="kit" 
                  value={x.value}  
                  activeColour='black' 
                  backgroundColour='white' 
                  onChange={handleChange} 
                  checked={x.value === selected.value}
                />
              )
            })}
          </Stack>
        </Inner>
      </Wrapper>
      
    </Box>
  )
}

export default KitPicker