import { getUser } from '../utils/auth'
import { useStaticQuery, graphql } from 'gatsby'

const useCustomShirt = () => {
  const query = useStaticQuery(graphql`
    query ShopPromoQuery {
      contentfulShop {
        customShirtPromoText
        customHomeShirt
        customAwayShirt
        customThirdShirt
        # customGoalkeeperShirt
      }

      allShopifyProductVariant(filter: { product: { id: { ne: null } } }) {
        edges {
          node {
            shopifyId
            product {
              handle
              title
              media {
                ... on ShopifyMediaImage {
                  image {
                    originalSrc
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const user = getUser()

  if (!user) {
    return {}
  }

  const kitType = user?.attributes['custom:kitType']
  const data = query.contentfulShop
  const variants = query.allShopifyProductVariant.edges

  const homeShirt = variants.find(
    x => x.node.shopifyId === data.customHomeShirt
  )
  const awayShirt = variants.find(
    x => x.node.shopifyId === data.customAwayShirt
  )
  const thirdShirt = variants.find(
    x => x.node.shopifyId === data.customThirdShirt
  )
  // const goalShirt = variants.find(x => (x.node.shopifyId === data.customGoalkeeperShirt))

  const product =
    kitType === 'goalkeeper'
      ? homeShirt
      : kitType === 'away'
      ? awayShirt
      : kitType === 'third'
      ? thirdShirt
      : homeShirt

  return { product: product, customShirtPromoText: data.customShirtPromoText }
}

export default useCustomShirt
