/** @jsx jsx */
import { useState, useEffect } from 'react'
import { jsx, Flex, Text, Input } from 'theme-ui';
import { NumberPicker } from '../form'
import { Inline } from 'raam'
import Kit from './kit'

const KitNumber = (props) => {

  const getDefaultNumber = () => {
    if (!props.default)
      return {
        ten: 0,
        unit: 0,
        total: 0
      }

    const n = parseInt(props.default.number);
    const tens = Math.floor(n/10)
    return {
      ten: tens,
      unit: n - (tens * 10),
      total: n
    }
  }

  const defaultNumber = getDefaultNumber();
  const [number, setNumber] = useState(defaultNumber.total)
  const [ten, setTen] = useState(defaultNumber.ten)
  const [unit, setUnit] = useState(defaultNumber.unit)
  const [name, setName] = useState(props.default ? props.default.name : '')

  const containerSX = {
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  }

  const tenChange = (value) => {
    const amount = value * 10
    setTen(amount)
    setNumber(amount + unit)
  }

  const unitChange = (value) => {
    setUnit(value)
    setNumber(value + ten)
  }

  const onNameChange = (e) => {
    setName(e.target.value)
  }

  const onChange = props.onChange ? props.onChange : null

  useEffect(() => {
    if (onChange)
      onChange(number, name)
  }, [number, name, onChange])

  const inputSX = {
    width: '220px',
    my: 5,
    textAlign: 'center'
  }

  return (
    <Flex sx={containerSX}>
      <Text>Select your squad number</Text>
      <Inline py={4} sx={{overflow: 'visible'}}>
        <NumberPicker 
          default={ten}
          onChange={tenChange} 
        />
        <NumberPicker 
          default={unit}
          onChange={unitChange} 
        />
      </Inline>
      <Kit src={props.kit?.kit} label={props.kit?.label} number={number} name={name} />
      <Text>Last name or nickname</Text>
      <Input sx={inputSX} type="text" onChange={onNameChange} value={name} />
    </Flex>
  )
}

export default KitNumber