const styles = {
  logo: {
    width: '138px',
    height: '138px',
    mb: 6
  },

  inner: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },

  intro: {
    mb: 9,
    maxWidth: '800px',
    lineHeight: 'body',

    'a': {
      color: 'inherit'
    }
  }
}

export default styles