import { responsive } from '../../utils/helpers'

const style = {
  kitContainer: {
    position: 'relative',
    display: 'inline-block',
    width: '300px',
    marginBottom: responsive(4, 0)
  },
  kit: {
    width: '300px',
    mb: 5
  },
  number: {
    position: 'absolute',
    top: '90px',
    left: '50%',
    transform: 'translateX(-50%)',
    fontFamily: "heading",
    fontSize: '100px',
  },
  name: {
    position: 'absolute',
    top: '60px',
    left: '50%',
    transform: 'translateX(-50%)',
    fontFamily: 'heading',
    fontSize: '26px',
  },
  button: {

  }
}

export default style