/** @jsx jsx */
import { useState, useContext } from 'react'
import { jsx, Heading, Text, Box, Input, Alert, Flex, Label, Button } from 'theme-ui';
import FullScreen from '../../layout/fullScreen'
import { Part, PartProgress, MultipartContext } from '../../form'
import Wrapper from '../../layout/wrapper'
import Inner from '../../layout/inner'
import styles from './styles'
import Logo from '../../../images/fgr-logo.png'
import { Powered } from '../../footer'
import { Link } from 'gatsby'
import queryString from 'query-string';
import update from 'immutability-helper';
import { login, setUser } from "../../../utils/auth"

const VerifyPassword = (props) => {

  const context = useContext(MultipartContext)
  const [state, setState] = useState({
    email: '',
    password: ''
  });
  const queryParams = queryString.parse(props.location?.search)
  const [error, setError] = useState(queryParams?.error_description ? {field: 'global', value: queryParams.error_description} : false)

  const formSX = {
    width: '300px',
    margin: '0 auto',
    textAlign: 'center'
  }

  const linkSX = {
    fontWeight: 700,
    textDecoration: 'underline',
    color: 'black',
    fontSize: 0,
    display: 'block'
  }

  const errorSX = {
    color: 'red',
    textAlign: 'left',
    pt: 2,
  }

  const validateFields = () => {
    const required = [
      'email',
      'password'
    ]

    let errors = false

    required.forEach((x) => {
      if (!state[x] || state[x] === '') {
        if (!errors) {
          errors = []
        }
        errors.push({
          field: x,
          value: 'This field is required'
        })
        return true
      }
    })
    setError(errors)
    return errors
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false)

    const validationErrors = validateFields()
    if (validationErrors)
      return

    await login(state.email, state.password).then(async (user) => {
      setUser(user)
      context.setScreen(context.screen + 1)
    }).catch((err) => {
      console.log('error', err);
      setError({
        field: 'global', 
        value: String(err)
      })
      
    });
  }

  const handleChange = (e) => {
    setState(update(state, {
      [e.target.name]: {
        $set: e.target.value
      }
    }))
  }

  const Error = ({type}) => {
    if (!error || !Array.isArray(error))
      return null

    const found = error.find((x) => {
      return x.field === type
    })

    if (!found)
      return null

    return <Text sx={errorSX}>{found.value}</Text>
  }

  return (
    <Part id={props.step}>
      <FullScreen backgroundColor="background">
        <PartProgress noInteraction />
        <Wrapper>
          <Inner sx={styles.inner}>
            <img sx={styles.logo} src={Logo} alt="Forest Green Rovers" />
            <Heading as='h2' variant="promoTitle" mb={3}>Confirm your password</Heading>
            <Text as="p" sx={styles.intro}>
              Please confirm your password to edit your profile
            </Text>
            <Box as="form" sx={formSX}>
              {error?.field === 'global' &&
                <Alert variant='error' mb={2}>{error?.value}</Alert>
              }
              <Box mb={6}>
                <Flex sx={{justifyContent: 'space-between'}} mb={2}>
                  <Label>Email address</Label>
                </Flex>
                <Input type="email" name="email" autoComplete="username" value={state.email} onChange={handleChange} />
                <Error type="email" />
              </Box>
              <Box mb={10}>
                <Label mb={2}>Password</Label>
                <Input type="password" name="password" autoComplete='current-password' value={state.password} onChange={handleChange} />
                <Error type="password" />
              </Box>
              <Button variant="transparent" mb={4} onClick={handleSubmit}>Submit</Button>
              <Text sx={linkSX} as={Link} to='/reset-password'>Forgot your password?</Text>
            </Box>
            <Powered />
          </Inner>
        </Wrapper>
      </FullScreen>
    </Part>
  )
}

export default VerifyPassword