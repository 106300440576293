/** @jsx jsx */
import { jsx, Heading, Text } from 'theme-ui';
import FullScreen from '../../layout/fullScreen'
import { Part, NextButton, PartProgress } from '../../form'
import Wrapper from '../../layout/wrapper'
import Inner from '../../layout/inner'
import styles from './styles'
import Logo from '../../../images/fgr-logo.png'
import { Powered } from '../../footer'
import { Link } from 'gatsby'

const Landing = (props) => {

  return (
    <Part id={props.step}>
      <FullScreen backgroundColor="background">
        <PartProgress noInteraction />
        <Wrapper>
          <Inner sx={styles.inner}>
            <Heading as='h1' variant="promoTitle" mb={6}>My FGR</Heading>
            <img sx={styles.logo} src={Logo} alt="Forest Green Rovers" />
            <Heading as='h2' variant="promoTitle" mb={3}>Join us</Heading>
            <Text as="p" sx={styles.intro}>
              Create an account to receive exclusive content and see all of your ticket and streaming purchases in one place. If you already have an MY FGR account you can <Link to="/login">log in</Link> here
            </Text>
            <NextButton variant='transparent'>Create account</NextButton>
            <Powered />
          </Inner>
        </Wrapper>
      </FullScreen>
    </Part>
  )
}

export default Landing