/** @jsx jsx */
import React, { useState, useEffect } from 'react'
import { jsx, Flex, Box, Text } from 'theme-ui'
import Carousel from '../carousel'
import { useStaticQuery, graphql } from 'gatsby'
import styles from './playerPicker.syles'
import { GatsbyImage } from 'gatsby-plugin-image'
import PlayerBlank from '../../images/player-blank.jpg'

const PlayerPicker = props => {
  const data = useStaticQuery(graphql`
    query FirstTeamPlayers {
      allContentfulPlayer(
        filter: {
          team_group: {
            elemMatch: {
              team: { elemMatch: { title: { eq: "FGR First Team" } } }
            }
          }
        }
      ) {
        nodes {
          title
          thumbnail {
            gatsbyImageData(layout: FIXED, width: 94, height: 94)
            file {
              url
            }
          }
          number
          team_group {
            title
          }
        }
      }
    }
  `)

  // remove duplicates and coaches
  const players = data.allContentfulPlayer.nodes.filter((x, i, self) => {
    const isNotDuplicate = i === self.findIndex(t => t.title === x.title)
    const group = x.team_group ? x.team_group[0] : false
    const isCoachingStaff =
      group && group.title.toLowerCase() === 'fgr coaching staff' ? true : false
    return isNotDuplicate && !isCoachingStaff
  })

  players.unshift({
    title: 'None',
    thumbnail: { file: { url: PlayerBlank } },
    number: '0',
    team_group: {
      title: 'none',
    },
  })

  const { onChange } = props
  const [selected, setSelected] = useState(
    props.default ? props.default : players[0]
  )
  const initialSlide = props.default
    ? players.findIndex(x => x.title === props.default.title)
    : 0

  useEffect(() => {
    if (onChange) onChange(selected)
  }, [selected, onChange])

  const handleChange = i => {
    setSelected(players[i])
  }

  const settings = {
    infinite: false,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    dots: false,
    arrows: true,
    focusOnSelect: true,
    afterChange: handleChange,
    initialSlide: initialSlide && initialSlide >= 0 ? initialSlide : 0,
  }

  return (
    <React.Fragment>
      <Flex sx={styles.container}>
        <img
          sx={styles.feature}
          src={selected.thumbnail.file.url}
          alt={selected.title}
        />
        <Text sx={styles.name}>{selected.title}</Text>
      </Flex>
      <Carousel settings={settings} sx={styles.carousel}>
        {players.map((x, i) => {
          return (
            <Box sx={styles.slide} key={i}>
              <Box
                sx={styles.thumbnailContainer}
                className={'thumbnail-container'}
              >
                {x.thumbnail.gatsbyImageData && (
                  <GatsbyImage
                    image={x.thumbnail.gatsbyImageData}
                    sx={styles.thumbnail}
                    alt={x.title}
                  />
                )}
                {!x.thumbnail.gatsbyImageData && (
                  <img
                    src={x.thumbnail?.file?.url}
                    sx={styles.thumbnail}
                    alt={x.title}
                  />
                )}
              </Box>
            </Box>
          )
        })}
      </Carousel>
    </React.Fragment>
  )
}

export default PlayerPicker
