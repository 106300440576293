/** @jsx jsx */
import { jsx, Heading } from 'theme-ui';
import FullScreen from '../../layout/fullScreen'
import { Part, NextButton, PartProgress, SkipButton } from '../../form'
import Wrapper from '../../layout/wrapper'
import Inner from '../../layout/inner'
import { KitNumber } from '../'
import styles from './styles'
import { getUser, isLoggedIn } from '../../../utils/auth'

const ShirtNumber = (props) => {

  const user = isLoggedIn() ? getUser() : false;

  return (
    <Part id={props.step}>
      <FullScreen backgroundColor={props.kit ? props.kit.color : 'primarydark'} sx={{transition: 'all 0.2s linear'}}>
        <PartProgress activeColour='white' inactiveColour='black' noInteraction />
        <Wrapper>
          <Inner sx={styles.inner}>
            <Heading as='h2' variant="promoTitle" mb={6}>Which shirt number would you wear?</Heading>
          </Inner>
        </Wrapper>
        <KitNumber 
          kit={props.kit} 
          onChange={props.onChange}
          default={props.editMode && user ? {
            number: user.attributes['custom:kitNumber'], 
            name: user.attributes['family_name']
          } : false}
        />
        <Wrapper>
          <Inner sx={styles.inner}>
            <NextButton variant='secondary'>Next</NextButton>
            <SkipButton variant="plain">Skip for now</SkipButton>
          </Inner>
        </Wrapper>
      </FullScreen>
    </Part>
  )
}

export default ShirtNumber