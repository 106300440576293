import Arrow from '../../images/carousel-arrow.svg'

export const arrows = {
  '.slick-arrow': {
    width: '60px',
    height: '60px',
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    border: 'none',
    borderRadius: '50%',
    color: 'black',
    transition: 'opacity 0.2s linear 0s',
    outline: 'none',
    opacity: 1,
    color: 'black',
    zIndex: 1,
    
    '&:before': {
      display: 'none'
    },
    '&:after': {
      content: '""',
      background: `url(${Arrow})`,
      backgroundSize: '18px 18px',
      width: '18px',
      height: '18px',
      display: 'block'
    }
  },
  '.slick-prev': {
    left: '18px',
  },
  '.slick-next': {
    right: '18px',

    '&:after': {
      transform: 'rotate(180deg)'
    }
  }
}