import { arrows } from '../carousel/style'

const styles = {
  container: {
    alignItems: 'center',
    flexDirection: 'column'
  },
  feature: {
    width: '170px',
    height: '170px',
    objectFit: 'cover',
  },
  carousel: {
    margin: '15px 0',
    ...arrows,

    '.slick-current': {

      '.thumbnail-container': {
        borderColor: 'primary'
      }
    },
  },
  slide: {
    width: '94px !important',
    height: '94px',
    margin: '0 5px',
  },
  thumbnailContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    border: '8px solid transparent',
  },
  thumbnail: {
    width: '100%',
    height: '100%',
  },
  name: {
    fontWeight: 700,
    my: 4
  }
}

export default styles