/** @jsx jsx */
import { jsx, Heading, Text, Button, Flex, Box, Grid, Input, Label, Alert } from 'theme-ui';
import React, { useState, useEffect } from 'react'
import Wrapper from '../layout/wrapper'
import Inner from '../layout/inner'
import { Link, navigate } from 'gatsby'
import { Inline, Wrap } from 'raam'
import { responsive } from '../../utils/helpers';
import Select from 'react-select'
import { selectStyles } from '../../styles/select'
import { DateSelect, ToolTip, Radio } from '../form'
import { Powered } from '../footer'
import update from 'immutability-helper'
import moment from 'moment';
import { getUser, isLoggedIn, register, updateUser } from '../../utils/auth'
import styles from './signUpForm.styles'
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import countryCodes from 'country-codes-list'
import 'react-phone-number-input/style.css'
import '../../styles/phoneinput.css'
import PhoneInput from 'react-phone-number-input'
import { useMediaQuery } from 'react-responsive'

gsap.registerPlugin(ScrollToPlugin);

const SignUpForm = (props) => {

  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 959px)' })
  const codes = countryCodes.customList('countryCode', '{countryNameEn}')
  const countryOptions = Object.keys(codes).map(key => (
    {
      value: key,
      label: codes[key]
    }
  ))
  const user = isLoggedIn() ? getUser() : false;
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [codeSent, setCodeSent] = useState();
  const [openCountryMenu, setOpenCountryMenu] = useState(false)
  const [verificationCode, setVerificationCode] = useState();
  const [state, setState] = useState({
    firstName: props.editMode && user 
      ? user.attributes['name'] 
      : '',
    lastName: props.lastName
      ? props.lastName
      : props.editMode && user
          ? user.attributes['family_name'] 
          : '',
    email: props.editMode && user 
      ? user.attributes['email'] 
      : '',
    phone: props.editMode && user 
      ? user.attributes['phone_number'] 
      : '',
    password: '',
    confirmPassword: '',
    country: props.editMode && user 
      ? user.attributes['custom:country'] 
      : '',
    dateOfBirth: props.editMode && user 
      ? user.attributes['custom:dateOfBirth'] 
      : '',
    stayUpdated: props.editMode && user 
      ? user.attributes['custom:stayUpdated'] 
      : 'no',
    readTerms: props.editMode
      ? 'yes'
      : 'no'
  })

  useEffect(() => {
    if (props.editMode && success) {
      navigate('/my-fgr')
    }
    // gsap.to(window, {duration: 0.3, scrollTo: 0});
  }, [success])

  const getRequiredErrors = () => {
    const required = [
      'firstName',
      'lastName',
      'email',
      'phone',
      'password',
      'confirmPassword',
      'country',
      'dateOfBirth',
    ]

    const requiredForEdit = required.filter(x => ( x !== 'password' && x !== 'confirmPassword' ));
    const fields = props.editMode ? requiredForEdit : required;

    return fields.reduce((total, x) => {
      if (!state[x] || state[x] === '')
        total.push({field: x, value: 'This field is required'})
      return total
    }, [])
  }

  const passwordsMatch = () => {
    return state.password === state.confirmPassword
  }

  const handleSubmit = async (e) => {
    
    setError(false)
    e.preventDefault();
    
    // Check required fields
    const requiredErrors = getRequiredErrors()
    if (requiredErrors.length) {
      setError(requiredErrors)
      return
    }

    // Check passwords match
    if (!props.editMode && !passwordsMatch()) {
      setError([
        {field: 'password', value: 'Passwords do not match'},
        {field: 'confirmPassword', value: 'Passwords do not match'}
      ])
      return
    }

    if (state.readTerms === 'no') {
      setError([{
        field: 'readTerms', value: 'You must confirm that you\'ve read our terms and conditions before submitting'
      }])
      return
    }

    const attributes = { 
      email: state.email,
      name: state.firstName, 
      family_name: state.lastName,
      phone_number: state.phone,
      'custom:country': state.country,
      'custom:dateOfBirth': state.dateOfBirth,
      'custom:stayUpdated': state.stayUpdated,
      'custom:kitNumber': props.number ? props.number.toString() : '',
      'custom:playerName': props.player ? props.player.title : '',
      'custom:playerNumber': props.player ? props.player.number.toString() : '',
      'custom:playerImage': props.player ? props.player.thumbnail?.file.url : '',
      'custom:kitType': props.kit ? props.kit.value : '',
      'custom:signup': moment().unix().toString(),
    }

    if (props.editMode) {
      // Update User
      const emailChanged = state.email !== user?.attributes?.email
      const updateRequest = await updateUser(emailChanged ? {...attributes, email_verified: false} : attributes)
      if (emailChanged) {
        setCodeSent(true)
      }
      if (updateRequest.success) {
        setSuccess(true)
      } else {
        console.log('error', updateRequest)
        setError({field: 'global', value: updateRequest?.error?.message})
      }
    } else {
      // Register User
      register(state.email, state.password, attributes)
        .then(() => {
          setSuccess(true)
        })
        .catch((err) => {
          console.log('err', err);
          setError({field: 'global', value: err})
        });
    }
  }

  const handleChange = (e, target = false, value = false) => {
    if (target && value) {
      setState(update(state, {
        [target]: {
          $set: value
        }
      }))
    } else if (e) {
      setState(update(state, {
        [e.target.name]: {
          $set: e.target.value
        }
      }))
    }
    
  }

  const handleResidenceChange = (option) => {
    setState(update(state, {
      country: {
        $set: option.value
      }
    }))
    setOpenCountryMenu(false)
  }

  const handleDateOfBirthChange = (day, month, year) => {
    setState(update(state, {
      dateOfBirth: {
        $set: `${day} ${month} ${year}`
      }
    }))
  }

  const Error = ({type, className}) => {
    if (!error || !Array.isArray(error))
      return null

    const found = error.find((x) => {
      return x.field === type
    })

    if (!found)
      return null

    return <Text className={className} sx={styles.error}>{found.value}</Text>
  }

  const handleCountryInputChange = (query, { action }) => {
    if (action === 'input-change') {
      setOpenCountryMenu(true)
    }
  }

  return (
    <Wrapper>
      <Inner sx={styles.inner}>
        <Heading variant="headingdark" as="h1" mb={4}>{props.editMode ? 'Edit Profile' : 'Sign For FGR'}</Heading>
        {!props.editMode &&
          <Text as="p" sx={styles.body}>If you already have a MY FGR account you can <Link to="/login">log in</Link> here</Text>
        }
        
        {/* <Text as="p" sx={styles.body}>My FGR is your personal profile and includes access to exclusive content and competitions. To manage your official membership account, please go to xxxxx xxxxx xxxxx xxxx.</Text>
        <Wrap sx={styles.buttons}>
          <Button variant="tertiary">Sign up with Facebook</Button>
          <Button variant="tertiary">Sign up with Google</Button>
          <Button variant="tertiary">Sign up with Apple</Button>
        </Wrap>
        <Text sx={styles.or}>Or</Text> */}
      </Inner>
      <Inner sx={styles.formContainer}>
        {error?.field === 'global' &&
          <Alert variant='error' mb={2}><p dangerouslySetInnerHTML={{__html: error?.value}}></p></Alert>
        }
        {success && !props.editMode &&
          <Alert variant='primary' mb={2}><p>We've just sent you an email to confirm your email address. Once you've done that, you can <Link to="/login">log in</Link></p></Alert>
        }
        {success && props.editMode && !codeSent &&
          <Alert variant='primary' mb={2}><p>Your profile has been updated</p></Alert>
        }
        {codeSent &&
          <Alert variant='primary' mb={2}><p>Your email address update is pending</p></Alert>
        }
        {(!success || props.editMode) &&
          <Box as="form" sx={styles.form} onSubmit={handleSubmit}>
            <Grid sx={styles.grid} columns={responsive(1, 'repeat(2, 1fr)')}>
              <Box>
                <Label mb={2}>First name</Label>
                <Input type="text" name='firstName' value={state.firstName} onChange={handleChange} />
                <Error type="firstName" />
              </Box>
              <Box>
                <Label mb={2}>Last name</Label>
                <Input type="text" name='lastName' value={state.lastName} onChange={handleChange} />
                <Error type="lastName" />
              </Box>
              {!props.editMode &&
                <Box>
                  <Flex sx={{justifyContent: 'space-between'}}>
                    <Label mb={2}>Email address</Label>
                    <ToolTip variant={isMobileOrTablet ? 'left' : 'right'}>If you already have an FGR streaming account then we recommend using the same email address for MY FGR, so that your account can be linked.</ToolTip>
                  </Flex>
                  <Input type="email" name='email' value={state.email} onChange={handleChange} />
                  <Error type="email" />
                </Box>
              }
              <Box>
                <Flex sx={{justifyContent: 'space-between'}}>
                  <Label mb={2}>Phone</Label>
                </Flex>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={state.phone}
                  onChange={value => handleChange(false, 'phone', value)}
                  defaultCountry="GB"
                  />
                <Error type="phone" />
              </Box>
              {!props.editMode &&
                <React.Fragment>
                  <Box mb={3}>
                    <Flex sx={{justifyContent: 'space-between'}}>
                      <Label mb={2}>Password</Label>
                      <Text sx={{flexShrink: '0', cursor: 'pointer'}} onClick={() => setShowPassword(!showPassword)}>{showPassword ? 'Hide' : 'Show'}</Text>
                    </Flex>
                    <Input type={showPassword ? 'text' : 'password'} name='password' value={state.password} onChange={handleChange} />
                    <Error type="password" />
                  </Box>
                  <Box>
                    <Flex sx={{justifyContent: 'space-between'}}>
                      <Label mb={2}>Confirm Password</Label>
                      <Text sx={{flexShrink: '0', cursor: 'pointer'}} onClick={() => setShowPassword(!showPassword)}>{showPassword ? 'Hide' : 'Show'}</Text>
                    </Flex>
                    <Input type={showPassword ? 'text' : 'password'} name='confirmPassword' value={state.confirmPassword} onChange={handleChange} />
                    <Error type="confirmPassword" />
                  </Box>
                </React.Fragment>
              }
              <Box>
                <Label mb={2}>Country or region</Label>
                <Select 
                  styles={selectStyles}
                  isSearchable
                  placeholder='Start typing...'
                  options={countryOptions}
                  onInputChange={handleCountryInputChange}
                  onBlur={() => setOpenCountryMenu(false)}
                  menuIsOpen={openCountryMenu}
                  onChange={handleResidenceChange}
                  value={state.country && state.country !== ''
                    ? countryOptions.filter(x => x.value === state.country)
                    : false}
                />
                <Error type="country" />
              </Box>
              <Box>
                <Label mb={2}>Date of birth</Label>
                <DateSelect 
                  default={state.dateOfBirth && state.dateOfBirth !== '' 
                    ? state.dateOfBirth 
                    : false
                  }
                  onChange={handleDateOfBirthChange} 
                />
                <Error type="dateOfBirth" />
              </Box>
            </Grid>
            
            <Text as="p" sx={styles.body}>We want you to be the first to know about New Signings, Competitions, Club News, Ticket Availability and occasional offers from partners. You can change your preferences or unsubscribe at any time</Text>
            <Wrap mb={10} sx={{justifyContent: 'center'}}>
              <Box sx={styles.radioLabel}>
                <Radio 
                  name="stayUpdated" 
                  value='yes' 
                  onChange={handleChange} 
                  checked={state.stayUpdated === 'yes'} 
                  label="Keep me updated" 
                  sx={styles.radio}
                />
              </Box>
              <Label sx={styles.radioLabel}>
                <Radio 
                  name="stayUpdated" 
                  value='no' 
                  onChange={handleChange} 
                  checked={state.stayUpdated === 'no'} 
                  label="No thanks" 
                  sx={styles.radio}
                />
              </Label>
              <Error type="stayUpdated" />
            </Wrap>
            <Text as="p" sx={styles.body}>If you are under 16 years of age, you must obtain consent from your parents or legal guardian to continue.</Text>
            <Text as="p" sx={styles.body}>
              By signing up, you agree to Forest Green Rovers Ltd using your personal data in accordance with our Privacy Policy. We use your data to personalise and improve your experience on our digital platforms, provide products and services you request from us, and carry out consumer profiling and market research. We won't ever pass your details to anyone else, unless required to do so by law.
            </Text>
            {!props.editMode && 
              <React.Fragment>
                <Error type="readTerms" sx={{mb: 2}} />
                <Wrap mb={10} sx={{justifyContent: 'center'}}>
                  <Label sx={styles.radioLabel}>
                    <Radio 
                      name="readTerms" 
                      value='yes' 
                      onChange={handleChange} 
                      checked={state.readTerms === 'yes'} 
                      label="Agree"
                      sx={styles.radio}
                    />
                  </Label>
                  <Label sx={styles.radioLabel}>
                    <Radio 
                      name="readTerms" 
                      value='no' 
                      onChange={handleChange} 
                      checked={state.readTerms === 'no'} 
                      label="Disagree"
                      sx={styles.radio}
                    />
                  </Label>
                </Wrap>
              </React.Fragment>
            }
            <Button mt={5} type="submit">{props.editMode ? 'Update' : 'Create account'}</Button>
            {!props.editMode &&
              <Text as="p" sx={styles.body} mt={5}>By clicking ‘Create account’ you agree to our <a href="/policies" target="_blank">terms and conditions</a></Text>
            }
            {error &&
              <Text sx={{...styles.error, textAlign: 'center'}}>There was a problem with your submission, please see individual fields for information</Text>
            }
          </Box>
        }
        <Powered />
      </Inner>
    </Wrapper>
  )
}

export default SignUpForm