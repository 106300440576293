/** @jsx jsx */
import { jsx, Heading, Text, Flex } from 'theme-ui';
import FullScreen from '../../layout/fullScreen'
import { Part, NextButton, PartProgress, SkipButton } from '../../form'
import Wrapper from '../../layout/wrapper'
import Inner from '../../layout/inner'
import { Kit } from '../'
import styles from './styles'

const Overview = (props) => {

  return (
    <Part id={props.step}>
      <FullScreen backgroundColor={props.kit ? props.kit.color : 'primarydark'} sx={{transition: 'all 0.2s linear'}}>
        <PartProgress activeColour='white' inactiveColour='black' noInteraction />
        <Wrapper>
          <Inner sx={styles.inner}>
            <Heading as='h2' variant="promoTitle" mb={6}>FGR want to sign you</Heading>
          </Inner>
        </Wrapper>
        <Flex sx={styles.inner}>
          <Kit 
            src={props.kit ? props.kit.kit : false} 
            label={props.kitName} 
            name={props.kitName} 
            number={props.kitNumber} 
          />
        </Flex>
        <Wrapper>
          <Inner sx={styles.inner}>
            <Text variant='small' sx={styles.intro} mb={4}>FGR Scouts have noticed your potential, this is your chance to join the Greenest Football Club in the World! You can change your name and number at any time.</Text>
            <NextButton variant='tertiary'>Sign for FGR</NextButton>
          </Inner>
        </Wrapper>
      </FullScreen>
    </Part>
  )
}

export default Overview

