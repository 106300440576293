/** @jsx jsx */
import { useState } from 'react'
import { jsx } from 'theme-ui';
import FullScreen from '../../layout/fullScreen'
import { Multipart, Part, PartProgress } from '../../form'
import { SignUpForm } from '../'
import { Landing, FavouritePlayer, FavouriteKit, ShirtNumber, Overview, VerifyPassword } from './'

const SCREEN_OPTIONS = [
  'verifyPassword',
  'landing',
  'favouritePlayer',
  'favouriteKit',
  'shirtNumber',
  'overview',
]

const ProfileBuilder = (props) => {

  const screens = SCREEN_OPTIONS.filter(x => (props[x]))
  const [player, setPlayer] = useState()
  const [kit, setKit] = useState()
  const [kitNumber, setKitNumber] = useState()
  const [kitName, setKitName] = useState()

  const handleCustomKit = (number, name) => {
    setKitNumber(number)
    setKitName(name)
  }

  return (
    <Multipart total={screens.length + 1}>
      {screens.map((x,i) => {
        switch (x) {
          case 'verifyPassword':
            return <VerifyPassword
              key={i}
              step={i} 
            />
          case 'landing':
            return <Landing
              key={i}
              step={i} 
            />
          case 'favouritePlayer':
            return <FavouritePlayer 
              key={i}
              step={i}
              onChange={(option) => setPlayer(option)} 
              editMode={props.editMode}
            />
          case 'favouriteKit':
            return <FavouriteKit
              key={i} 
              step={i}
              kit={kit}
              onChange={(option) => setKit(option)}
              editMode={props.editMode}
            />
          case 'shirtNumber':
            return <ShirtNumber
              key={i}
              step={i} 
              kit={kit}
              onChange={handleCustomKit}
              editMode={props.editMode}
            />
          case 'overview':
            return <Overview 
              key={i}
              step={i}
              kit={kit}
              kitName={kitName}
              kitNumber={kitNumber}
            />
          default:
            return null
        }
      })}
      <Part id={screens.length}>
        <FullScreen backgroundColor='background'> 
          <PartProgress noInteraction />
          <SignUpForm 
            kit={kit} 
            lastName={kitName} 
            number={kitNumber} 
            player={player}
            editMode={props.editMode}
          />
        </FullScreen>
      </Part>
    </Multipart>
  )
}

export default ProfileBuilder