/** @jsx jsx */
import { jsx, Box, Text, Button } from 'theme-ui';
import Pink from '../../images/kit/goal.png'
import Green from '../../images/kit/home.png'
import Grey from '../../images/kit/away.png'
import Blue from '../../images/kit/blue.png'
import style from './kit.style'
import useCustomShirt from '../../hooks/useCustomShirt'
import { Link } from 'gatsby'

const Kit = (props) => {

  const { product } = useCustomShirt();

  const kitSrc = () => {
    if (props.src)
      return props.src

    switch (props.type) {
      case 'home':
      default:
        return Green
      case 'away':
        return Grey
      case 'third':
        return Blue
      case 'goalkeeper':
        return Pink
    }
  }

  const Number = () => {
    if (!props.number)
      return null
    return <Text sx={style.number}>{props.number}</Text>
  }

  const Name = () => {
    if (!props.name)
      return null
    return <Text sx={style.name}>{props.name}</Text>
  }

  return (
    <Box sx={style.kitContainer}>
      <img src={kitSrc()} className={props.className} sx={style.kit} alt={props.label} />
      <Number />
      <Name />
      {props.buyButton &&
        <Button variant="tertiary" as={Link} to={`/shop/${product?.node?.product?.handle}`}>Buy your shirt</Button>
      }
    </Box>
  )
}

export default Kit