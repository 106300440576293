/** @jsx jsx */
import { jsx, Heading } from 'theme-ui';
import FullScreen from '../../layout/fullScreen'
import { Part, NextButton, PartProgress, SkipButton } from '../../form'
import Wrapper from '../../layout/wrapper'
import Inner from '../../layout/inner'
import styles from './styles'
import { PlayerPicker } from '../'
import { getUser, isLoggedIn } from '../../../utils/auth'

const FavouritePlayer = (props) => {

  const user = isLoggedIn() ? getUser() : false;

  return (
    <Part id={props.step}>
      <FullScreen backgroundColor="primarydark">
        <PartProgress activeColour='white' inactiveColour='black' noInteraction />
        <Wrapper>
          <Inner sx={styles.inner}>
            <Heading as='h2' variant="promoTitle" mb={6}>Do you have a favourite FGR player?</Heading>
          </Inner>
        </Wrapper>
        <PlayerPicker 
          default={props.editMode && user ? {
            title: user.attributes['custom:playerName'],
            thumbnail: {file: { url: user.attributes['custom:playerImage']}},
            number: user.attributes['custom:playerNumber']
          } : false}
          onChange={(option) => props.onChange(option)} 
        />
        <Wrapper>
          <Inner sx={styles.inner}>
            <NextButton variant='secondary'>Next</NextButton>
            <SkipButton variant="plain">Skip for now</SkipButton>
          </Inner>
        </Wrapper>
      </FullScreen>
    </Part>
  )
}

export default FavouritePlayer