const inner = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
}

const styles = {

  body: {
    lineHeight: 'body',
    mb: 3,

    'a': {
      color: 'black',
      fontWeight: '700'
    }
  },
  inner: inner,
  formContainer: {
    ...inner,
    maxWidth: '750px',
    margin: '0 auto',
  },
  form: {
    textAlign: 'center',
  },
  or: {
    my: 10,
    fontWeight: 700
  },
  grid: {
    width: '100%',
    mb: 17,
  },
  buttons: {
    justifyContent: 'center'
  },
  radioLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  radio: {

    '.label': {
      fontSize: 3,
      lineHeight: 'body'
    }
    
  },
  error: {
    color: 'red',
    textAlign: 'left',
    pt: 2,
  }
}

export default styles